import React from 'react';
import {NavLink} from "react-router-dom";
import Layout from "../../Layouts/Layout";
import PageTitle from "../../_components/PageTitle";
import SingleProductBody from "./Layout/SingleProductBody";
import PagePreFooter from "../../_components/PagePreFooter";
import productImage from "../../../assets/img/services/agro-01.jpg";

const SpecialPalmOil: React.FC = () => {

  const productDescription: string = `Premium grade palm oil with less than 3% free fatty acid (FFA) content, extracted from the mesocarp of palm fruits. In order to make high quality refined products, it is essential to have crude palm oil of a good quality.`;

  const lists:string[] = [
    `Cooking & Frying`,
    `As nutritious supplement in food and beverage industries`,
    `In soap and detergent manufacturing`
  ];


  return (
    <>
      <Layout>
        <PageTitle
          title1={`Our Products`}
          link1={<NavLink to="/products"> Products</NavLink>}
          link2={`Special Palm Oil`} />
        <SingleProductBody
          productName={'Special Palm Oil'}
          productDescription={productDescription}
          productImage={productImage}
          listPoints={lists}
        />
        <PagePreFooter />
      </Layout>
    </>
  );
}

export default SpecialPalmOil;