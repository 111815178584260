import React from 'react';
import Layout from "../Layouts/Layout";
import PageTitle from "../_components/PageTitle";
import CallFunctions from "../_components/CallFunctions";
// import {NavLink} from "react-router-dom";

const Careers = () => {

  CallFunctions()

  return (
    <>
      <Layout>
        <PageTitle title1={`Careers`} link1={`Careers`} />

        <>
          {/* Careers */}
          <section className="error-area">
            <div className="error-item" style={{height: '200px'}}>
              <div className="d-table">
                <div className="d-table-cell">
                  <div className="error-text">
                    <p>
                      Sorry! We are currently not recruiting.{" "}
                      <i className="bx bxs-sad bx-flip-horizontal bx-tada" />
                    </p>
                    {/*<span>*/}
                    {/*  Oops! The page you are looking for does not exist. It might have been*/}
                    {/*  moved or deleted.*/}
                    {/*</span>*/}
                    {/*<NavLink to="/">Return to Home</NavLink>*/}
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* End Careers */}
        </>


      </Layout>
    </>
  );
}

export default Careers;