import React from 'react';
import {NavLink} from "react-router-dom";
import Layout from "../../Layouts/Layout";
import PageTitle from "../../_components/PageTitle";
import PagePreFooter from "../../_components/PagePreFooter";
import serviceMainImage from "../../../assets/img/services/main-supply.jpg";
import serviceImage1 from "../../../assets/img/services/supply-01.jpg";
import serviceImage2 from "../../../assets/img/services/supply-02.jpg";
import serviceImage3 from "../../../assets/img/services/supply-03.jpg";
import SingleServiceBody from "./Layout/SingleServiceBody";
import serviceImage4 from "../../../assets/img/services/supply-04.jpg";
import serviceImage5 from "../../../assets/img/services/supply-05.jpg";
import serviceImage6 from "../../../assets/img/services/supply-06.jpg";
import serviceImage7 from "../../../assets/img/services/supply-07.jpg";
import serviceImage8 from "../../../assets/img/services/supply-08.jpg";
import serviceImage9 from "../../../assets/img/services/supply-09.jpg";
import serviceImage10 from "../../../assets/img/services/supply-10.jpg";
import serviceImage11 from "../../../assets/img/services/supply-11.jpg";
import serviceImage12 from "../../../assets/img/services/supply-12.jpg";

const SupplyChainManagement: React.FC = () => {

  const serviceDescriptionP1: string = `Our multi-faceted supply chain capabilities allow us to deliver compelling solutions and greater value to your business. We have capability to provide substantial supply chain management growth opportunity in Food & Beverage, healthcare, high value commodities and industrial product, Government Retail, Oil and Gas, Chemicals. Our decision to re-brand and re-focus the company not only as an essential strategy to undertake but it lied in solidly with our vision to advance in the logistics segment. Our business is all about understanding the needs of your business, and providing the ideal supply chain management solution to ensure its optimum growth. At a micro level, we use understanding of your business to integrate all separate elements of your supply chain, and let you view it as a whole.`;

  const serviceDescriptionP2: string = `As a leading supply chain management company, we utilize the best resources, capabilities, and technology in the market to design solutions that provide the greatest efficiencies for client supply chain.`;


  return (
    <>
      <Layout>
        <PageTitle
          title1={`Our Services`}
          link1={<NavLink to="/services"> Services</NavLink>}
          link2={`Supply Chain Management`} />
        <SingleServiceBody
          serviceName={'Supply Chain Management'}
          serviceDescriptionP1={serviceDescriptionP1}
          serviceDescriptionP2={serviceDescriptionP2}
          serviceMainImage={serviceMainImage}
          serviceImage1={serviceImage1}
          serviceImage2={serviceImage2}
          serviceImage3={serviceImage3}
          serviceImage4={serviceImage4}
          serviceImage5={serviceImage5}
          serviceImage6={serviceImage6}
          serviceImage7={serviceImage7}
          serviceImage8={serviceImage8}
          serviceImage9={serviceImage9}
          serviceImage10={serviceImage10}
          serviceImage11={serviceImage11}
          serviceImage12={serviceImage12}
        />
        <PagePreFooter />
      </Layout>
    </>
  );
}

export default SupplyChainManagement;