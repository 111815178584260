import {NavLink} from "react-router-dom";

const ProductsBody = () => {

  return (
    <>
      {/* Service */}
      <section className="service-area pt-100 pb-70">
        <div className="container">
          <div className="row">
            <div className="col-sm-6 col-lg-3">
              <div className="service-item">
                <img src="assets/img/home-one/service-shape.png" alt="Service" />
                <img src="assets/img/home-one/service-shape2.png" alt="Service" />
                <i className="flaticon-mechanical-arm" />
                <h3>
                  <NavLink to="/palm-olein" style={{fontSize: 20}}>
                    Palm Olein
                  </NavLink>
                </h3>
                <p>
                  Palm Olein is a light yellow edible oil obtained from the fractionation of Refined Bleached and Deodorized Palm Oil.
                </p>
                <NavLink to="/palm-olein" className="service-link" >
                  Read More ...
                </NavLink>
              </div>
            </div>
            <div className="col-sm-6 col-lg-3">
              <div className="service-item">
                <img src="assets/img/home-one/service-shape.png" alt="Service" />
                <img src="assets/img/home-one/service-shape2.png" alt="Service" />
                <i className="bx bx-bulb" />
                <h3>
                  <NavLink to="/palm-stearin" style={{fontSize: 20}}>
                    Palm Stearin
                  </NavLink>
                </h3>
                <p>
                  Palm Stearin is the solid fraction obtained from the fractionation of Refined Bleached and Deodorized Palm Oil.
                </p>
                <NavLink to="/palm-stearin" className="service-link" >
                  Read More ...
                </NavLink>
              </div>
            </div>
            <div className="col-sm-6 col-lg-3">
              <div className="service-item">
                <img src="assets/img/home-one/service-shape.png" alt="Service" />
                <img src="assets/img/home-one/service-shape2.png" alt="Service" />
                <i className="flaticon-automation" />
                <h3>
                  <NavLink to="/crude-palm-oil" style={{fontSize: 20}}>
                    Crude Palm Oil
                  </NavLink>
                </h3>
                <p>
                  Crude Palm Kernel Oil (CPKO) is a light yellow crude oil, extracted from the palm kernels, containing mainly lauric acid.
                </p>
                <NavLink to="/crude-palm-oil" className="service-link" >
                  Read More ...
                </NavLink>
              </div>
            </div>
            <div className="col-sm-6 col-lg-3">
              <div className="service-item">
                <img src="assets/img/home-one/service-shape.png" alt="Service" />
                <img src="assets/img/home-one/service-shape2.png" alt="Service" />
                <i className="flaticon-lab-tool" />
                <h3>
                  <NavLink to="/special-palm-oil" style={{fontSize: 20}}>
                    Special Palm Oil
                  </NavLink>
                </h3>
                <p>
                  Premium grade palm oil with less than 3% free fatty acid (FFA) content, extracted from the mesocarp of palm fruits.
                </p>
                <NavLink to="/special-palm-oil" className="service-link" >
                  Read More ...
                </NavLink>
              </div>
            </div>
            <div className="col-sm-6 col-lg-3">
              <div className="service-item">
                <img src="assets/img/home-one/service-shape.png" alt="Service" />
                <img src="assets/img/home-one/service-shape2.png" alt="Service" />
                <i className="flaticon-oil-drill" />
                <h3>
                  <NavLink to="/refined-kernel-palm-oil" style={{fontSize: 20}}>
                    Refined Palm Kernel Oil
                  </NavLink>
                </h3>
                <p>
                  Refined Palm Kernel Oil (RPKO) is the result of the refining of crude palm kernel oil.
                </p>
                <NavLink to="/refined-kernel-palm-oil" className="service-link" >
                  Read More ...
                </NavLink>
              </div>
            </div>
            <div className="col-sm-6 col-lg-3">
              <div className="service-item">
                <img src="assets/img/home-one/service-shape.png" alt="Service" />
                <img src="assets/img/home-one/service-shape2.png" alt="Service" />
                <i className="flaticon-robotic-arm" />
                <h3>
                  <NavLink to="/palm-fatty-acid-distillate" style={{fontSize: 20}}>
                    Palm Fatty Acid Distillate
                  </NavLink>
                </h3>
                <p>
                  Palm Fatty Acid Distillate (PFAD) contains a very high free fat.
                </p>
                <NavLink to="/palm-fatty-acid-distillate" className="service-link" >
                  Read More ...
                </NavLink>
              </div>
            </div>
            <div className="col-sm-6 col-lg-3">
              <div className="service-item">
                <img src="assets/img/home-one/service-shape.png" alt="Service" />
                <img src="assets/img/home-one/service-shape2.png" alt="Service" />
                <i className="flaticon-robotic-arm" />
                <h3>
                  <NavLink to="/bleached-palm-oil" style={{fontSize: 20}}>
                    Refined Bleached & Deodorized Palm Oil
                  </NavLink>
                </h3>
                <p>
                  This is the result of refining Special Palm Oil.
                </p>
                <NavLink to="/bleached-palm-oil" className="service-link" >
                  Read More ...
                </NavLink>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* End Service */}
    </>


  );
}

export default ProductsBody;