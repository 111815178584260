import React from 'react';
import {NavLink} from "react-router-dom";
import Layout from "../../Layouts/Layout";
import PageTitle from "../../_components/PageTitle";
import SingleProductBody from "./Layout/SingleProductBody";
import PagePreFooter from "../../_components/PagePreFooter";
import productImage from "../../../assets/img/services/agro-02.jpg";

const RefinedPalmKernelOil: React.FC = () => {

  const productDescription: string = `Refined Palm Kernel Oil (RPKO) is the result of the refining of crude palm kernel oil.`;

  const lists:string[] = [
    `In ice cream production`,
    `In butter and margarine industries`,
    `In chocolate and confectionery industries`,
    `Soap and detergent industries`
  ];


  return (
    <>
      <Layout>
        <PageTitle
          title1={`Our Products`}
          link1={<NavLink to="/products"> Products</NavLink>}
          link2={`Refined Palm Kernel Oil`} />
        <SingleProductBody
          productName={'Refined Palm Kernel Oil'}
          productDescription={productDescription}
          productImage={productImage}
          listPoints={lists}
        />
        <PagePreFooter />
      </Layout>
    </>
  );
}

export default RefinedPalmKernelOil;