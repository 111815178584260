import React from 'react';
import footerLogo from "../../assets/img/logo.png";
import {Link, NavLink} from "react-router-dom";
import CopyRight from "./CopyRight";

const Footer = () => {
    return (
      <>
        {/* Footer */}
        <footer className="pt-100 pb-5">
          <div className="container">
            <div className="row">
              <div className="col-sm-6 col-lg-3">
                <div className="footer-item">
                  <div className="footer-logo">
                    <Link to="/" className="logo-link">
                      <img src={footerLogo} alt="Logo" />
                    </Link>
                    <p>
                      BASGI Group is a diversified business conglomerate in Nigeria - Africa, renowned for excellent business practices.
                    </p>
                    {/*<ul>*/}
                    {/*  <li>*/}
                    {/*    <a href="#" target="_blank">*/}
                    {/*      <i className="bx bxl-facebook" />*/}
                    {/*    </a>*/}
                    {/*  </li>*/}
                    {/*  <li>*/}
                    {/*    <a href="#" target="_blank">*/}
                    {/*      <i className="bx bxl-twitter" />*/}
                    {/*    </a>*/}
                    {/*  </li>*/}
                    {/*  <li>*/}
                    {/*    <a href="#" target="_blank">*/}
                    {/*      <i className="bx bxl-pinterest-alt" />*/}
                    {/*    </a>*/}
                    {/*  </li>*/}
                    {/*  <li>*/}
                    {/*    <a href="#" target="_blank">*/}
                    {/*      <i className="bx bxl-linkedin" />*/}
                    {/*    </a>*/}
                    {/*  </li>*/}
                    {/*</ul>*/}
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-lg-3">
                <div className="footer-item">
                  <div className="footer-service">
                    <h3>Our Services</h3>
                    <ul>
                      <li>
                        <NavLink to="/oil-gas">Oil & Gas</NavLink>
                      </li>
                      <li>
                        <NavLink to="/supply-management">Supply Chain Management</NavLink>
                      </li>
                      <li>
                        <NavLink to="/agro-supply">Agro-allied Supply Services</NavLink>
                      </li>
                      <li>
                        <NavLink to="/facility-management">Facility & Equipment Management</NavLink>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-lg-3">
                <div className="footer-item">
                  <div className="footer-touch">
                    <h3>Address</h3>
                    <ul>
                      <li>
                        <i className="bx bx-location-plus" />
                        <h4>Address</h4>
                        <span>Suite C7, <br/>
                          MKO Abiola Gardens Estate Shopping Complex, <br/>
                          Alausa-Ikeja,Lagos-State.
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-lg-3">
                <div className="footer-item">
                  <div className="footer-touch">
                    <h3>Other Contacts</h3>
                    <ul>
                      <li>
                        <i className="bx bxs-phone-call" />
                        <h4>Phone</h4>
                        <span>
                        +234 803 322 6656
                        <br/>
                        +234 807 722 6656
                        </span>
                      </li>
                      <li>
                        <i className="bx bx-mail-send" />
                        <h4>Email</h4>
                        <span>
                          basginigerialimited@gmail.com
                          <br/>
                          baybadmus@yahoo.com
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </footer>
        {/* End Footer */}

        <CopyRight />
      </>

    );
}

export default Footer;