import ContactForm from "./ContactForm";
import ContactAddress from "./ContactAddress";

const ContactBody = () => {

  return (
    <>
      {/* Contact */}
      <section className="contact-area pt-100 pb-70">
        <div className="container">
          <div className="row">

            <div className="col-lg-5">
              <ContactAddress />
            </div>
            <div className="col-lg-7">
              <ContactForm />
            </div>

          </div>
        </div>
      </section>
      {/* End Contact */}
    </>

  );
}

export default ContactBody;