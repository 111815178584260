import React from "react";
import aboutImage1 from "../../assets/img/about/about1.jpg"
import aboutImage2 from "../../assets/img/about/about2.jpg"
// import aboutImage3 from "../../assets/img/about/about3.jpg"
import aboutImage4 from "../../assets/img/about/about4.jpg"
import aboutImage5 from "../../assets/img/about/about5.jpg"
import aboutShape2 from "../../assets/img/about/about2.png"
// import aboutShape3 from "../../assets/img/about/about3.png"
import aboutShape4 from "../../assets/img/about/about4.png"
import aboutShape5 from "../../assets/img/about/about5.png"

const AboutUsBody = () => {

  return (
        <>
          {/* About */}
          <section className="about-area ptb-100">
            <div className="container">
              <div className="row align-iems-center">
                <div className="col-lg-6">
                  <div className="section-title">
                    <span className="sub-title">About Us</span>
                    <h2>
                      We commit ourselves to achieving excellence
                    </h2>
                  </div>
                  <div className="about-content">
                    <p>
                      BASGI has over 20 years of combined experience in the world of Petroleum, Supply Chain Management, Logistics, Transportation, Warehousing and Distribution, Contracts, Property/Facility Management. We help our clients to improve productivity, -drive cost reduction and maximize their Return On Investment (ROI) When a company decides to outsource logistics operations (in whole or in parts) Basgi is ready to guide them through this complicated and stressful time. From meeting our clients’ distribution and warehousing challenge to optimizing networks and creating product level visibility. We have the expertise to move goods and services to the remotest part of the country BASGI ensures driving business success by effectively managing the supply chain with broad based experience and expertise.
                    </p>
                    <ul>
                      <li>
                        <i className="bx bx-check" />
                        Agro Allied Supply Services
                      </li>
                      <li>
                        <i className="bx bx-check" />
                        Oil & Gas
                      </li>
                      <li>
                        <i className="bx bx-check" />
                        Facility & Equipment Management
                      </li>
                      <li>
                        <i className="bx bx-check" />
                        Supply Chain Management
                      </li>
                    </ul>
                    {/*<img src="assets/img/home-one/about-man.png" alt="About" />*/}
                    {/*<img src="assets/img/home-one/about-signature.png" alt="About" />*/}
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="about-img-wrap">
                    <div className="about-img-slider owl-theme owl-carousel">
                      <div className="about-img-item">
                        <img src={aboutImage1} alt="About" />
                      </div>
                      <div className="about-img-item">
                        <img src={aboutImage2} alt="About" />
                      </div>
                      <div className="about-img-item">
                        <img src={aboutImage4} alt="About" />
                      </div>
                      <div className="about-img-item">
                        <img src={aboutImage5} alt="About" />
                      </div>
                    </div>
                    <div className="about-shape">
                      <img src={aboutShape2} alt="About" />
                      {/*<img src={aboutShape3} alt="About" />*/}
                      <img src={aboutShape4} alt="About" />
                      <img src={aboutShape5} alt="About" />
                    </div>
                    <div className="about-year">
                      <h2>
                        20 <span>Years</span>+
                      </h2>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* End About */}
        </>
  );
}

export default AboutUsBody;