import React from 'react';
import {NavLink} from "react-router-dom";
import Layout from "../../Layouts/Layout";
import PageTitle from "../../_components/PageTitle";
import SingleProductBody from "./Layout/SingleProductBody";
import PagePreFooter from "../../_components/PagePreFooter";
import productImage from "../../../assets/img/services/agro-05.jpg";

const PalmOlein: React.FC = () => {

  const productDescription: string = `Palm Olein is a light yellow edible oil obtained from the fractionation of Refined Bleached and Deodorized Palm Oil, which is separated in two fractions by partial crystallization. The liquid fraction is called Palm Olein.`;

  const lists:string[] = [
    `For cooking and frying`,
    `As salad oil`,
    `In mayonnaise`,
    `In creams and spreads`
  ];

  return (
    <>
      <Layout>
        <PageTitle
          title1={`Our Products`}
          link1={<NavLink to="/products"> Products</NavLink>}
          link2={`Palm Olein`} />
        <SingleProductBody
          productName={'Palm Olein'}
          productDescription={productDescription}
          productImage={productImage}
          listPoints={lists}
        />
        <PagePreFooter />
      </Layout>
    </>
  );
}

export default PalmOlein;