import React from 'react';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
// import bg04 from "../../assets/images/bg/04.png";
import bannerImage from "../../assets/img/home/banner-bg.jpg";
import bannerImage1 from "../../assets/img/home/banner-bg1.jpg";
import {Link} from "react-router-dom";
// import {Link} from "react-router-dom";

const HomeBannerArea = () => {
    return (
      <>
        {/* Banner */}
        <OwlCarousel autoplay={true} autoplayTimeout={7000} smartSpeed={1000} className='' loop={true} margin={0} items={1} nav={true}>
          <div className='item'>
            <div className="banner-area" style={{backgroundImage: `url(${bannerImage})`,height: '700px'}}>
              <div className="d-table">
                <div className="d-table-cell">
                  <div className="container">

                    <div className="row align-items-center">
                      {/*<div className="col-lg-11">*/}
                        <div className="banner-content">
                          <h1>We Commit Ourselves To Achieving Excellence </h1>
                          <p>
                            We utilize the best resources, capabilities and technology in the market to design solutions that provides the greatest efficiencies for our clients.
                          </p>
                          <Link className="cmn-btn" to="/about">
                            Discover More
                            <i className="bx bx-right-arrow-alt" />
                          </Link>
                        </div>
                      {/*</div>*/}
                      {/*<div className="col-lg-1">*/}
                      {/*  <div className="banner-img">*/}
                      {/*    /!*<img src="assets/img/home-two/banner-main.png" alt="Banner" />*!/*/}
                      {/*  </div>*/}
                      {/*</div>*/}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='item'>
            <div className="banner-area" style={{backgroundImage: `url(${bannerImage1})`,height: '700px'}}>
              <div className="d-table">
                <div className="d-table-cell">
                  <div className="container">

                    <div className="row align-items-center">
                      {/*<div className="col-lg-11">*/}
                        <div className="banner-content">
                          <h1>Reliable And Prompt Supply Of Products</h1>
                          <p>
                            We specialize in the supply of outstanding quality products at a very reasonable prices to our customers.
                          </p>
                          <Link className="cmn-btn" to="/about">
                            Discover More
                            <i className="bx bx-right-arrow-alt" />
                          </Link>
                        </div>
                      {/*</div>*/}
                      {/*<div className="col-lg-1">*/}
                      {/*  <div className="banner-img">*/}
                      {/*    /!*<img src="assets/img/home-two/banner-main.png" alt="Banner" />*!/*/}
                      {/*  </div>*/}
                      {/*</div>*/}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </OwlCarousel>
        {/* End Banner */}
      </>

    );
}

export default HomeBannerArea;