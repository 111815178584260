import React from 'react';
import {NavLink} from "react-router-dom";
import Layout from "../../Layouts/Layout";
import PageTitle from "../../_components/PageTitle";
import SingleProductBody from "./Layout/SingleProductBody";
import PagePreFooter from "../../_components/PagePreFooter";
import productImage from "../../../assets/img/services/agro-04.jpg";

const PalmFattyAcidDistillate: React.FC = () => {

  const productDescription: string = `Palm Fatty Acid Distillate (PFAD) contains a very high free fatty acid level and is mainly used in the soap and detergent industry. PFAD is obtained as a by-product of the refining process.`;

  const lists:string[] = [

  ];


  return (
    <>
      <Layout>
        <PageTitle
          title1={`Our Products`}
          link1={<NavLink to="/products"> Products</NavLink>}
          link2={`Palm Fatty Acid Distillate`} />
        <SingleProductBody
          productName={'Palm Fatty Acid Distillate'}
          productDescription={productDescription}
          productImage={productImage}
          listPoints={lists}
        />
        <PagePreFooter />
      </Layout>
    </>
  );
}

export default PalmFattyAcidDistillate;