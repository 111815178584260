import React from 'react';
import {NavLink} from "react-router-dom";
import Layout from "../../Layouts/Layout";
import PageTitle from "../../_components/PageTitle";
import PagePreFooter from "../../_components/PagePreFooter";
import serviceMainImage from "../../../assets/img/services/main-oil.jpg";
import serviceImage1 from "../../../assets/img/services/oil-01.jpg";
import serviceImage2 from "../../../assets/img/services/oil-02.jpg";
import serviceImage3 from "../../../assets/img/services/oil-03.jpg";
import serviceImage4 from "../../../assets/img/services/oil-04.jpg";
import serviceImage5 from "../../../assets/img/services/oil-05.jpg";
import SingleServiceBody from "./Layout/SingleServiceBody";

const OilGas: React.FC = () => {

  const serviceDescriptionP1: string = `BASGI is an integrated downstream petroleum company.The company is an indigenous company and a big time player in the downstream petroleum sector of the economy. In our own little way,the company has been creating employment and adding to the Gross Domestic Product(GDP) of the Nigerian economy.Our company is a prominent player in the petroleum sector of Nigeria. The company's operations span across the entire regions in the country. Our products include Petrol(PMS), Diesel(AGO) and Kerosene(DPK).`;

  const serviceDescriptionP2: string = `Basgi specializes in the distribution of bulk petroleum products and marketing. The company provides petroleum logistics services with her healthy and sound trucks for the smooth delivery of petroleum products to our teeming customers and industrial concerns.`;

  const serviceDescriptionP3: string = `BASGI management philosophy is based saliently on solid foundation,truth,confidence and vigor. We have extended our services to include the supply of LPFO and wide range of industrial and automotive lubricants. We are well positioned to leverage on domestic and international expertise in the growth and development of key tenets of the Nigerian fuels retailing industry.`;


  return (
    <>
      <Layout>
        <PageTitle
          title1={`Our Services`}
          link1={<NavLink to="/services"> Services</NavLink>}
          link2={`Oil & Gas`} />
        <SingleServiceBody
          serviceName={'Oil & Gas'}
          serviceDescriptionP1={serviceDescriptionP1}
          serviceDescriptionP2={serviceDescriptionP2}
          serviceDescriptionP3={serviceDescriptionP3}
          serviceMainImage={serviceMainImage}
          serviceImage1={serviceImage1}
          serviceImage2={serviceImage2}
          serviceImage3={serviceImage3}
          serviceImage4={serviceImage4}
          serviceImage5={serviceImage5}
        />
        <PagePreFooter />
      </Layout>
    </>
  );
}

export default OilGas;