import React from 'react';
import {NavLink} from "react-router-dom";
import Layout from "../../Layouts/Layout";
import PageTitle from "../../_components/PageTitle";
import SingleProductBody from "./Layout/SingleProductBody";
import PagePreFooter from "../../_components/PagePreFooter";
import productImage from "../../../assets/img/services/agro-07.png";

const CrudePalmKernelOil: React.FC = () => {

  const productDescription: string = `Crude Palm Kernel Oil (CPKO) is a light yellow crude oil, extracted from the palm kernels, containing mainly lauric acid.`;

  const lists:string[] = [
    `In ice cream`,
    `In margarine`,
    `In chocolate and confectionery products`,
    `In soap and detergent industries`
  ];


  return (
    <>
      <Layout>
        <PageTitle
          title1={`Our Products`}
          link1={<NavLink to="/products"> Products</NavLink>}
          link2={`Crude Palm Kernel Oil`} />
        <SingleProductBody
          productName={'Crude Palm Kernel Oil'}
          productDescription={productDescription}
          productImage={productImage}
          listPoints={lists}
        />
        <PagePreFooter />
      </Layout>
    </>
  );
}

export default CrudePalmKernelOil;