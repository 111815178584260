import React from 'react';
import {NavLink} from "react-router-dom";
import Layout from "../../Layouts/Layout";
import PageTitle from "../../_components/PageTitle";
import PagePreFooter from "../../_components/PagePreFooter";
import serviceMainImage from "../../../assets/img/services/main-agro.jpg";
import serviceImage1 from "../../../assets/img/services/agro-01.jpg";
import serviceImage2 from "../../../assets/img/services/agro-02.jpg";
import serviceImage3 from "../../../assets/img/services/agro-03.jpg";
import SingleServiceBody from "./Layout/SingleServiceBody";

const AgroAlliedSupply: React.FC = () => {

  const serviceDescriptionP1: string = `Basgi is a big time supplier of Agro-Allied Products like Special Palm Oil, Crude Palm Kernel Oil (CPKO), Refined Bleached and Deodorized Palm Oil(RBDO), Palm Fatty Acid Distillate (PFAD), Olein, Palm Stearin, Refined Palm Kernel Oil (RPKO). We specialize in the supply of outstanding quality products at a very reasonable prices to our customers. We usually ensure we supply to customer’s specification and needs.`;

  const serviceDescriptionP2: string = `Our customers are assured of reliable and prompt supply of products year round. Basgi is a Nigerian Market Leader in the Agro-allied supplies to soap and detergent industries, Confectioneries, Ice-cream manufacturers, diary product industries, and other related companies that rely on the company for her raw materials. It is our goal to continuously satisfy the needs of our teeming customers, we continuously increase and improve our key operational parameters in order to increase our efficiency.`;

  const serviceDescriptionP3: string = `In order to consolidate our leadership role in the Agro-allied sector of the economy, we invest so much money on research and development. We shall continuously penetrate the retail market in order to increase our market share of the sector. While we believe in environmentally friendly and sustainable operations, we have made our presence felt in the Agro-allied sector in terms of volume sales and marketing of her products.`;


  return (
    <>
      <Layout>
        <PageTitle
          title1={`Our Services`}
          link1={<NavLink to="/services"> Services</NavLink>}
          link2={`Agro Allied Supply Services`} />
        <SingleServiceBody
          serviceName={'Agro Allied Supply Services'}
          serviceDescriptionP1={serviceDescriptionP1}
          serviceDescriptionP2={serviceDescriptionP2}
          serviceDescriptionP3={serviceDescriptionP3}
          serviceMainImage={serviceMainImage}
          serviceImage1={serviceImage1}
          serviceImage2={serviceImage2}
          serviceImage3={serviceImage3}
          serviceVideo2={`https://www.youtube.com/watch?v=16D0J2NpVi8`}
        />
        <PagePreFooter />
      </Layout>
    </>
  );
}

export default AgroAlliedSupply;