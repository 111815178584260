import React from "react";

const ContactForm = () => {
  return (
    <>
      <div className="contact-item contact-left">
        <h3>Contact Us</h3>
        {/*<p>*/}
        {/*  Lorem ipsum dolor sit amet, consectetur adipisicing elit. Officiis velit*/}
        {/*  libero vero, aliquid magnam sed, quidem labore unde aspernatur esse iusto*/}
        {/*  quo quas, dolorum atque dignissimos asperiores. Ab, est accusantium.*/}
        {/*</p>*/}
        <ul>
          <li>
            <i className="bx bx-location-plus" />
            Address: <br/>
            Suite C7, <br/>
            MKO Abiola Gardens Estate Shopping Complex, <br/>
            Alausa-Ikeja,Lagos-State.
          </li>
          <li>
            <i className="bx bx-mail-send" />
            Email: <br/>
            basginigerialimited@gmail.com
            <br/>
            baybadmus@yahoo.com
          </li>
          <li>
            <i className="bx bx-phone-call" />
            Phone: <br/>
            +234 803 322 6656
            <br/>
            +234 807 722 6656
          </li>
        </ul>
      </div>
    </>
  );
}

export default ContactForm;