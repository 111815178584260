export const productSideBarList = [
  {
    id: 1,
    name: 'Palm Olein',
    href: "/palm-olein",
  },
  {
    id: 2,
    name: 'Palm Stearin',
    href: "/palm-stearin",
  },
  {
    id: 3,
    name: 'Crude Palm Kernel Oil',
    href: "/crude-palm-oil",
  },
  {
    id: 4,
    name: 'Special Palm Oil',
    href: "/special-palm-oil",
  },
  {
    id: 5,
    name: 'Refined Palm Kernel Oil',
    href: "/refined-palm-kernel-oil",
  },
  {
    id: 6,
    name: 'Palm Fatty Acid Distillate',
    href: "/palm-fatty-acid-distillate",
  },
  {
    id: 7,
    name: 'Refined Bleached & Deodorized Palm Oil',
    href: "/bleached-palm-oil",
  },
];