import React, {useEffect} from 'react';
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
// const router = createBrowserRouter( /* application routes are defined here */ );
// import $ from "jquery/dist/jquery.slim"
//
// import Swal from 'sweetalert2'
// import 'bootstrap/dist/css/bootstrap.css';
// import 'bootstrap/dist/js/bootstrap.js';
// import $ from "jquery/dist/jquery.slim"
// import * as $ from "jquery";
// import {allFunc} from "./assets/js/custom"

import "./assets/css/bootstrap.min.css";
import "./assets/css/meanmenu.css";
import "./assets/css/boxicons.min.css";
import "./assets/fonts/flaticon.css";
import "./assets/css/owl.carousel.min.css";
import "./assets/css/owl.theme.default.min.css";
import "./assets/css/odometer.min.css";
import "./assets/css/magnific-popup.min.css";
import "./assets/css/style.css";
import "./assets/css/dark.css";
import "./assets/css/responsive.css";


import Home from "./app/Home/Home";
import Contact from "./app/Contact/Contact";
import About from "./app/AboutUs/About";
import PageNotFound from "./app/404/PageNotFound";
import Services from "./app/Services/Services";
import Products from "./app/Products/Products";
import Careers from "./app/Careers/Carrers";
import PalmOlein from "./app/Products/Items/PalmOlein";
import PalmStearin from "./app/Products/Items/PalmStearin";
import CallFunctions from "./app/_components/CallFunctions";
import CrudePalmKernelOil from "./app/Products/Items/CrudePalmKernelOil";
import SpecialPalmOil from "./app/Products/Items/SpecialPalmOil";
import RefinedPalmKernelOil from "./app/Products/Items/RefinedPalmKernelOil";
import PalmFattyAcidDistillate from "./app/Products/Items/PalmFattyAcidDistillate";
import RefinedBleachedAndDeodorizedPalmOil from "./app/Products/Items/RefinedBleachedAndDeodorizedPalmOil";
import AgroAlliedSupply from "./app/Services/items/AgroAlliedSupply";
import OilGas from "./app/Services/items/OilGas";
import FacilityEquipmentManagement from "./app/Services/items/FacilityEquipmentManagement";
import SupplyChainManagement from "./app/Services/items/SupplyChainManagement";


function App() {

  // CallFunctions()

  // Extracts pathname property(key) from an object
  const { pathname } = useLocation();

  useEffect(() => {
    allFunc($);
    window.scrollTo(0, 0);

  }, [pathname]);


  return (
    <>
      {/*<Routes>*/}
      {/*  <Route path='/' key={`Home`} element={<Home/>} />*/}
      {/*  <Route path='about' key={`About`} element={<About/>} />*/}
      {/*  <Route path='services' key={`Services`} element={<Services/>} />*/}
      {/*  <Route path='products' key={`Products`} element={<Products/>} />*/}
      {/*  <Route path='products/palm-olein' key={`gh`} element={<PalmOlein />} />*/}
      {/*  <Route path='products/palm-stearin' key={`PalmStearin`} element={<PalmStearin />} />*/}
      {/*  <Route path='careers' key={`Careers`} element={<Careers />} />*/}
      {/*  <Route path='contact' key={`Contact`} element={<Contact />} />*/}
      {/*  <Route path="*" element={<PageNotFound />} />*/}
      {/*</Routes>*/}

      {/*<Router>*/}
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='about' element={<About />} />
          <Route path='services' element={<Services />} />
          <Route path='agro-supply' element={<AgroAlliedSupply />} />
          <Route path='oil-gas' element={<OilGas />} />
          <Route path='facility-management' element={<FacilityEquipmentManagement />} />
          <Route path='supply-management' element={<SupplyChainManagement />} />

          <Route path='products' element={<Products />} />
          <Route path='palm-olein' element={<PalmOlein />} />
          <Route path='palm-stearin' element={<PalmStearin />} />
          <Route path='crude-palm-oil' element={<CrudePalmKernelOil />} />
          <Route path='special-palm-oil' element={<SpecialPalmOil />} />
          <Route path='refined-palm-kernel-oil' element={<RefinedPalmKernelOil />} />
          <Route path='palm-fatty-acid-distillate' element={<PalmFattyAcidDistillate />} />
          <Route path='bleached-palm-oil' element={<RefinedBleachedAndDeodorizedPalmOil />} />
          <Route path='careers' element={<Careers />} />
          <Route path='contact' element={<Contact />} />
          <Route path="*" element={<PageNotFound />} />
        </Routes>
      {/*</Router>*/}

    </>
  );
}

export default App;