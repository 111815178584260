import React from 'react';
import clientLogo01 from "../../assets/img/logo/09.jpg";
import clientLogo02 from "../../assets/img/logo/10.jpg";
import clientLogo03 from "../../assets/img/logo/11.jpg";
import clientLogo04 from "../../assets/img/logo/12.jpg";
import clientLogo05 from "../../assets/img/logo/13.jpg";
import clientLogo06 from "../../assets/img/logo/14.jpg";
import clientLogo07 from "../../assets/img/logo/15.jpg";
import clientLogo08 from "../../assets/img/logo/16.jpg";
import clientLogo09 from "../../assets/img/logo/17.jpg";
import clientLogo10 from "../../assets/img/logo/18.jpg";
import clientLogo11 from "../../assets/img/logo/19.jpg";
import clientLogo12 from "../../assets/img/logo/20.jpg";

const HomeClientLogoArea = () => {
    return (
      <>
        {/* Logo */}
        <div className="logo-area pt-4 pb-1">
          <div className="container">
            <div className="row">
              <div className="col-lg-5">
                <div className="logo-item">
                  <img src={clientLogo01} alt="Logo" />
                </div>
              </div>
              <div className="col-lg-5">
                <div className="logo-item">
                  <img src={clientLogo02} alt="Logo" />
                </div>
              </div>
              <div className="col-lg-5">
                <div className="logo-item">
                  <img src={clientLogo03} alt="Logo" />
                </div>
              </div>
              <div className="col-lg-5">
                <div className="logo-item">
                  <img src={clientLogo04} alt="Logo" />
                </div>
              </div>
              <div className="col-lg-5">
                <div className="logo-item">
                  <img src={clientLogo05} alt="Logo" />
                </div>
              </div>
              <div className="col-lg-5">
                <div className="logo-item">
                  <img src={clientLogo06} alt="Logo" />
                </div>
              </div>
              <div className="col-lg-5">
                <div className="logo-item">
                  <img src={clientLogo07} alt="Logo" />
                </div>
              </div>
              <div className="col-lg-5">
                <div className="logo-item">
                  <img src={clientLogo08} alt="Logo" />
                </div>
              </div>
              <div className="col-lg-5">
                <div className="logo-item">
                  <img src={clientLogo09} alt="Logo" />
                </div>
              </div>
              <div className="col-lg-5">
                <div className="logo-item">
                  <img src={clientLogo10} alt="Logo" />
                </div>
              </div>
              <div className="col-lg-5">
                <div className="logo-item">
                  <img src={clientLogo11} alt="Logo" />
                </div>
              </div>
              <div className="col-lg-5">
                <div className="logo-item">
                  <img src={clientLogo12} alt="Logo" />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Logo */}
      </>

    );
}

export default HomeClientLogoArea;