import React from 'react';
import Layout from "../Layouts/Layout";
import PageTitle from "../_components/PageTitle";
import ContactBody from "./ContactBody";
import CallFunctions from "../_components/CallFunctions";

const Contact = () => {

  CallFunctions()

  return (
    <>
      <Layout>
        <PageTitle title1={`Contact Us`}  link1={`Contact Us`} link2={``} />
        <ContactBody />
      </Layout>
    </>
  );
}

export default Contact;