import React from 'react';

import serviceHomeImage1 from "../../assets/img/home/foreign1.jpg";
import serviceHomeImage2 from "../../assets/img/home/foreign2.jpg";
import serviceHomeImage3 from "../../assets/img/home/foreign3.jpg";
import serviceHomeImage4 from "../../assets/img/home/foreign4.jpg";
import {Link} from "react-router-dom";

const HomeProductsServices = () => {
    return (
      <>
        {/* Foreign */}
        <div className="foreign-area-two" style={{ marginTop: '0px' }}>
          <div className="container">
            <div className="section-title">
              <h2>We Are Reliable in Product & Service Delivery</h2>
            </div>
            <div className="row">
              <div className="col-sm-6 col-lg-3">
                <div className="foreign-item">
                  <Link to="/facility-management">
                    <img src={serviceHomeImage1} alt="Foreign" />
                  </Link>
                  <div className="foreign-bottom">
                    <h3>
                      <Link to="/facility-management">Facility Management</Link>
                    </h3>
                    {/*<span>Industrial Company</span>*/}
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-lg-3">
                <div className="foreign-item">
                  <Link to="/supply-management">
                    <img src={serviceHomeImage3} alt="Foreign" />
                  </Link>
                  <div className="foreign-bottom">
                    <h3>
                      <Link to="/supply-management">Supply Chain</Link>
                    </h3>
                    {/*<span>Industrial Construction</span>*/}
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-lg-3">
                <div className="foreign-item">
                  <Link to="/agro-supply">
                    <img src={serviceHomeImage2} alt="Foreign" />
                  </Link>
                  <div className="foreign-bottom">
                    <h3>
                      <Link to="/agro-supply">Agro-Allied Supplies</Link>
                    </h3>
                    {/*<span>Industrial Company</span>*/}
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-lg-3">
                <div className="foreign-item">
                  <Link to="/facility-management">
                    <img src={serviceHomeImage4} alt="Foreign" />
                  </Link>
                  <div className="foreign-bottom">
                    <h3>
                      <Link to="/supply-management">Logistics</Link>
                    </h3>
                    {/*<span>Logistic System</span>*/}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Foreign */}
      </>

    );
}

export default HomeProductsServices;