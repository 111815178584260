import React from 'react';
import Layout from "../Layouts/Layout";
import PageTitle from "../_components/PageTitle";
import {NavLink} from "react-router-dom";

const PageNotFound = () => {

  return (
    <>
      <Layout>
        {/*<PageTitle title1={`404 Error! Page Not Found`} link1={``} />*/}

        <>
          {/* 404 */}
          <section className="error-area">
            <div className="error-item">
              <div className="d-table">
                <div className="d-table-cell">
                  <div className="error-text">
                    <h1>404!</h1>
                    <p>
                      Sorry! The Page Was Not Found{" "}
                      <i className="bx bxs-sad bx-flip-horizontal bx-tada" />
                    </p>
                    <span>
                      Oops! The page you are looking for does not exist. It might have been
                      moved or deleted.
                    </span>
                    <NavLink to="/">Return to Home</NavLink>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* End 404 */}
        </>


      </Layout>
    </>
  );
}

export default PageNotFound;