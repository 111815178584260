import titleBackground from "../../assets/img/page-title-bg.jpg";
import {NavLink} from "react-router-dom";
import React from "react";

type Props = {
  title1: string,
  link1?: string | React.ComponentElement<any, any>,
  link2?: string | React.ComponentElement<any, any>,
  // backgroundImage: any
}

const PageTitle = (props: Props) => {
  return (
      <>
        {/* Page Title */}
        <div className="page-title-area" style={{backgroundImage: `url(${titleBackground})`, height: '300px' }}>
          <div className="d-table">
            <div className="d-table-cell">
              <div className="container">
                <div className="title-item" style={{textAlign: 'left'}}>
                  <h2>{props.title1}</h2>
                  <ul>
                    <li>
                      <NavLink to="/">Home</NavLink>
                    </li>
                    <li>
                      <span>/</span>
                    </li>
                    <li>{props.link1}</li>

                    {props.link2 ? (
                      <>
                      <li>
                        <span>/</span>
                      </li>
                      <li>{props.link2}</li>
                      </>
                    ) : (
                      <span></span>
                    )}


                  </ul>

                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Page Title */}
      </>
  );
}

export default PageTitle;