import React from 'react';

import {Link} from "react-router-dom";

const HomeAbout = () => {
    return (
        <>
        {/* Benefit */}
        <section className="benefit-area benefit-area-two" style={{marginTop: 0}}>
          <div className="container-fluid">
            <div className="row m-0">
              <div className="col-lg-7">
                <div className="benefit-content">
                  <div className="section-title">
                    <h2>
                      We are masters in what we do.
                    </h2>
                  </div>
                  <p>
                    With over 10 years’ experience in the business of excellent delivery of goods and services, we have become the preferred choice of our clients and have maintained and exponential growth rate of the number of our clients. We are a principled company that places our customer satisfaction above all and we always maintain a customer-centric approach to business.
                  </p>
                  <div className="row m-0">
                    <div className="col-sm-6 col-lg-6">
                      <div className="benefit-inner">
                        <i className="flaticon-darts" />
                        <h4>Customer-Centric Approach</h4>
                      </div>
                    </div>
                    <div className="col-sm-6 col-lg-6">
                      <div className="benefit-inner">
                        <i className="flaticon-customer" />
                        <h4>Backed By 100% Customer References</h4>
                      </div>
                    </div>
                    <div className="col-sm-6 col-lg-6">
                      <div className="benefit-inner">
                        <i className="flaticon-security-purposes" />
                        <h4>Fast, Scalable, &amp; Reliable</h4>
                      </div>
                    </div>
                    <div className="col-sm-6 col-lg-6">
                      <div className="benefit-inner">
                        <i className="flaticon-artificial-intelligence" />
                        <h4>Outstanding Quality Products</h4>
                      </div>
                    </div>
                  </div>
                  <Link className="cmn-btn" to="/contact">
                    Contact Us Today
                    <i className="bx bx-right-arrow-alt" />
                  </Link>
                  <div className="benefit-shape">
                    <img src="assets/img/home-one/benefit-shape.png" alt="Benefit" />
                  </div>
                </div>
              </div>
              <div className="col-lg-5">
                <div className="benefit-img">
                  <img src="assets/img/home-two/benefit-main.jpg" alt="Benefit" />
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* End Benefit */}
      </>

    );
}

export default HomeAbout;