import React from 'react';
import Layout from "../Layouts/Layout";
import PageTitle from "../_components/PageTitle";
import ServicesBody from "./ServicesBody";
import PagePreFooter from "../_components/PagePreFooter";
import CallFunctions from "../_components/CallFunctions";

const Services = () => {

  CallFunctions()

  return (
    <>
      <Layout>
        <PageTitle title1={`Our Services`}  link1={`Our Services`} link2={``} />
        <ServicesBody />
        <PagePreFooter />
      </Layout>
    </>
  );
}

export default Services;