import React from "react";
import {NavLink} from "react-router-dom";

const ServicesBody = () => {

  return (
    <>
      {/* Service */}
      <section className="service-area-two service-area-three pt-100 pb-70 flu">
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-6 col-lg-3">
              <div className="service-item">
                <i className="flaticon-factory" />
                <h3>
                  <NavLink to="/agro-supply">Agro-allied Supply Services</NavLink>
                </h3>
                <p>
                  Basgi is a big time supplier of Agro-Allied Products like Special Palm Oil, Crude Palm Kernel Oil (CPKO), Refined Bleached and ....
                </p>
                <NavLink to="/agro-supply" className="service-link">Read More ...</NavLink>
              </div>
            </div>
            <div className="col-sm-6 col-lg-3">
              <div className="service-item">
                <i className="flaticon-defribillator" />
                <h3>
                  <NavLink to="/facility-management">Facility & Equipment Management</NavLink>
                </h3>
                <p>
                  This is the bedrock upon which we build our relationships—relationship with customers, investors and employees who live in Nigerian. deals ....
                </p>
                <NavLink to="/facility-management" className="service-link">Read More ...</NavLink>
              </div>
            </div>
            <div className="col-sm-6 col-lg-3">
              <div className="service-item">
                <i className="flaticon-oil-drill" />
                <h3>
                  <NavLink to="/oil-gas">Oil & Gas</NavLink>
                </h3>
                <p>
                  BASGI is an integrated downstream petroleum company.The company is an indigenous company and a big time player in the downstream petroleum sector of the economy ....
                </p>
                <NavLink to="/oil-gas" className="service-link">Read More ...</NavLink>
              </div>
            </div>
            <div className="col-sm-6 col-lg-3">
              <div className="service-item">
                <i className="flaticon-mechanical-arm" />
                <h3>
                  <NavLink to="/supply-management">Supply Chain Management</NavLink>
                </h3>
                <p>
                  Our multi-faceted supply chain capabilities allow us to deliver compelling solutions and greater value to your business. We have capability to ....
                </p>
                <NavLink to="/supply-management" className="service-link">Read More ...</NavLink>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* End Service */}
    </>

  );
}

export default ServicesBody;