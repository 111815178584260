import React from 'react';
import Layout from "../Layouts/Layout";
import PageTitle from "../_components/PageTitle";
import AboutUsBody from "./AboutUsBody";
import PagePreFooter from "../_components/PagePreFooter";
import CallFunctions from "../_components/CallFunctions";

const About = () => {

  CallFunctions()

  return (
    <>
      <Layout>
        <PageTitle title1={`About Us`} link1={`About Us`} link2={``} />
        <AboutUsBody />
        <PagePreFooter />
      </Layout>
    </>
  );
}

export default About;