import React from 'react';
import {NavLink} from "react-router-dom";
import Layout from "../../Layouts/Layout";
import PageTitle from "../../_components/PageTitle";
import SingleProductBody from "./Layout/SingleProductBody";
import PagePreFooter from "../../_components/PagePreFooter";
import productImage from "../../../assets/img/services/agro-06.png";

const PalmStearin: React.FC = () => {

  const productDescription: string = `Palm Stearin is the solid fraction obtained from the fractionation of Refined Bleached and Deodorized Palm Oil. It is mainly used by the food industry and soap and detergent industries.`;

  const lists:string[] = [
    `In dough for biscuits`,
    `In chocolate and confectionery products`,
    `In snacks, crisps`,
    `Soap and detergent industries`
  ];


  return (
    <>
      <Layout>
        <PageTitle
          title1={`Our Products`}
          link1={<NavLink to="/products"> Products</NavLink>}
          link2={`Palm Stearin`} />
        <SingleProductBody
          productName={'Palm Stearin'}
          productDescription={productDescription}
          productImage={productImage}
          listPoints={lists}
        />
        <PagePreFooter />
      </Layout>
    </>
  );
}

export default PalmStearin;