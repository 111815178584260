import React from 'react';
import {NavLink} from "react-router-dom";
import Layout from "../../Layouts/Layout";
import PageTitle from "../../_components/PageTitle";
import PagePreFooter from "../../_components/PagePreFooter";
import serviceMainImage from "../../../assets/img/services/main-facility.jpg";
import serviceImage1 from "../../../assets/img/services/facility-01.jpg";
import serviceImage2 from "../../../assets/img/services/facility-02.jpg";
import serviceImage3 from "../../../assets/img/services/facility-03.jpg";
import serviceImage4 from "../../../assets/img/services/facility-04.jpg";
import serviceImage5 from "../../../assets/img/services/facility-05.jpg";
import serviceImage6 from "../../../assets/img/services/facility-06.jpg";
import serviceImage7 from "../../../assets/img/services/facility-07.jpg";
import serviceImage8 from "../../../assets/img/services/facility-08.jpg";
import serviceImage9 from "../../../assets/img/services/facility-09.jpg";
import serviceImage10 from "../../../assets/img/services/facility-10.jpg";
import serviceImage11 from "../../../assets/img/services/facility-11.jpg";
import serviceImage12 from "../../../assets/img/services/facility-12.jpg";
import serviceImage13 from "../../../assets/img/services/facility-13.jpg";
import serviceImage14 from "../../../assets/img/services/facility-14.jpg";
import SingleServiceBody from "./Layout/SingleServiceBody";

const FacilityEquipmentManagement: React.FC = () => {

  const serviceDescriptionP1: string = `This is the bedrock upon which we build our relationships—relationship with customers, investors and employees who live in Nigerian. deals in sales, rent and offer professional services which include Air-conditioning unit Installation and services, Professional Cleaning and Janitorial Services, Professional Fumigation / Pest Control services, Generator Installation and Services in Nigeria. We achieve this by discussing your requirements, then we design a perfect strategic model in management of facilities, it is our duty to inculcate a good maintenance culture on properties we manage. We also provide a range industrial cleaning for commercial buildings and consultancy services. We are committed to providing effective tailor made facility management services to our clients which include banking and other formal sector of the economy. We strive to achieve the highest standards of Quality Cleaning, Health, Safety and Environment Management throughout our service delivery. Nothing is more important to us than the health and safety of our employees and the people who use our services.`;

  const serviceDescriptionP2: string = `We look towards building a mutually beneficial relationship with all stakeholders of the economy. The word for us is partnership with both internal and external customers. We do not want to see our customers just as numbers, we want to be able to measure our success by the positive impact we have created for businesses in the country and we want to create an environment where our customers would see us as friends, where a continuous mutually beneficial relationship shall strive. We hope to achieve this in the country.`;

  const serviceDescriptionP3: string = `One of our major clients is Guaranty Trust Bank PLC.`;


  return (
    <>
      <Layout>
        <PageTitle
          title1={`Our Services`}
          link1={<NavLink to="/services"> Services</NavLink>}
          link2={`Facility & Equipment Management`} />
        <SingleServiceBody
          serviceName={'Facility & Equipment Management'}
          serviceDescriptionP1={serviceDescriptionP1}
          serviceDescriptionP2={serviceDescriptionP2}
          serviceDescriptionP3={serviceDescriptionP3}
          serviceMainImage={serviceMainImage}
          serviceVideo7={`https://www.youtube.com/watch?v=csVZly2IvNs`}
          serviceVideo11={`https://www.youtube.com/watch?v=Xhe1zziBLQk`}
          serviceImage1={serviceImage1}
          serviceImage2={serviceImage2}
          serviceImage3={serviceImage3}
          serviceImage4={serviceImage4}
          serviceImage5={serviceImage5}
          serviceImage6={serviceImage6}
          serviceImage7={serviceImage7}
          serviceImage8={serviceImage8}
          serviceImage9={serviceImage9}
          serviceImage10={serviceImage10}
          serviceImage11={serviceImage11}
          serviceImage12={serviceImage12}
          serviceImage13={serviceImage13}
          serviceImage14={serviceImage14}
        />
        <PagePreFooter />
      </Layout>
    </>
  );
}

export default FacilityEquipmentManagement;