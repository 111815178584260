import preFooterImage from "../../assets/img/book-main.jpg";
import {NavLink} from "react-router-dom";

const PagePreFooter = () => {
  return (
    <>
      {/* Book */}
      <div className="book-area pb-100">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="book-img">
                <img src={preFooterImage} alt="Book" />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="book-content">
                <h3>
                  How About We Make Something New Together!<br/> Get in touch with us today.
                </h3>
                <NavLink to="/contact">Contact Us Now</NavLink>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* End Book */}
    </>

  );
}

export default PagePreFooter;