import React from 'react';

const CopyRight = () => {
    return (
      <>
        {/* Copyright */}
        <div className="copyright-area">
          <div className="container">
            <div className="copyright-item">
              <p>
                Copyright &copy; { new Date().getFullYear() } Basgi Nigeria Limited. Crafted By{" "}
                <a href="https://www.loftyedge.com/" target={"_blank"} rel="noreferrer" >
                  LoftyEdge Networks
                </a>
              </p>
            </div>
          </div>
        </div>
        {/* End Copyright */}
      </>


    );
}

export default CopyRight;