export const serviceSideBarList = [
  {
    id: 1,
    name: 'Agro Allied Supply Services',
    href: "/agro-supply",
  },
  {
    id: 2,
    name: 'Oil & Gas',
    href: "/oil-gas",
  },
  {
    id: 3,
    name: 'Facility & Equipment Management',
    href: "/facility-management",
  },
  {
    id: 4,
    name: 'Supply Chain Management',
    href: "/supply-management",
  }
];