import React from 'react';
import {NavLink} from "react-router-dom";
import Layout from "../../Layouts/Layout";
import PageTitle from "../../_components/PageTitle";
import SingleProductBody from "./Layout/SingleProductBody";
import PagePreFooter from "../../_components/PagePreFooter";
import productImage from "../../../assets/img/services/agro-03.jpg";

const RefinedBleachedAndDeodorizedPalmOil: React.FC = () => {

  const productDescription: string = `This is the result of refining Special Palm Oil. It contains between 0.05% and 0.15% free fatty acid.`;

  const lists:string[] = [
    `For cooking and frying`,
    `In dairy product factories`,
    `As a nutritious supplement in food and beverage industries`,
    `In soap and detergent manufacturers`
  ];


  return (
    <>
      <Layout>
        <PageTitle
          title1={`Our Products`}
          link1={<NavLink to="/products"> Products</NavLink>}
          link2={`Refined Bleached & Deodorized Palm Oil`} />
        <SingleProductBody
          productName={'Refined Bleached & Deodorized Palm Oil'}
          productDescription={productDescription}
          productImage={productImage}
          listPoints={lists}
        />
        <PagePreFooter />
      </Layout>
    </>
  );
}

export default RefinedBleachedAndDeodorizedPalmOil;