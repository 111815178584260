import React from 'react';
import offerImage01 from "../../assets/img/home/home-services-01.jpg";
import offerImage02 from "../../assets/img/home/home-services-02.jpg";
import offerImage03 from "../../assets/img/home/home-services-03.jpg";
import offerImage04 from "../../assets/img/home/home-services-04.jpg";
import offerShapeImage from "../../assets/img/home-two/offer-shape.png";
import {Link} from "react-router-dom";
// import {Link} from "react-router-dom";

const HomeServices = () => {
    return (
      <>
        {/* Offer */}
        <section className="offer-area pb-5 pt-100">
          <div className="container">
            <div className="section-title">
              {/*<span className="sub-title">Services</span>*/}
              {/*<h2>Our Services</h2>*/}
            </div>
            <div className="offer-slider owl-theme owl-carousel">
              <div className="offer-item">
                <div className="offer-top">
                  <img src={offerImage01} alt="Offer" />
                  <i className="flaticon-3d-print" />
                </div>
                <div className="offer-bottom">
                  <h3>
                    <Link to="/agro-supply">Agro-Allied Supplies</Link>
                  </h3>
                  {/*<p>*/}
                  {/*  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do*/}
                  {/*  eiusmod{" "}*/}
                  {/*</p>*/}
                  {/*<a className="offer-link" href="service-details.html">*/}
                  {/*  Read More*/}
                  {/*  <i className="bx bx-plus" />*/}
                  {/*</a>*/}
                </div>
                <div className="offer-shape">
                  <img src={offerShapeImage} alt="Offer" />
                </div>
              </div>
              <div className="offer-item">
                <div className="offer-top">
                  <img src={offerImage02} alt="Offer" />
                  <i className="flaticon-3d-print" />
                </div>
                <div className="offer-bottom">
                  <h3>
                    <Link to="/supply-management">Supply Chain</Link>
                  </h3>
                  {/*<p>*/}
                  {/*  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do*/}
                  {/*  eiusmod{" "}*/}
                  {/*</p>*/}
                  {/*<a className="offer-link" href="service-details.html">*/}
                  {/*  Read More*/}
                  {/*  <i className="bx bx-plus" />*/}
                  {/*</a>*/}
                </div>
                <div className="offer-shape">
                  <img src={offerShapeImage} alt="Offer" />
                </div>
              </div>
              <div className="offer-item">
                <div className="offer-top">
                  <img src={offerImage03} alt="Offer" />
                  <i className="flaticon-robotic-arm" />
                </div>
                <div className="offer-bottom">
                  <h3>
                    <Link to="/facility-management">Facility Management</Link>
                  </h3>
                  {/*<p>*/}
                  {/*  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do*/}
                  {/*  eiusmod{" "}*/}
                  {/*</p>*/}
                  {/*<a className="offer-link" href="service-details.html">*/}
                  {/*  Read More*/}
                  {/*  <i className="bx bx-plus" />*/}
                  {/*</a>*/}
                </div>
                <div className="offer-shape">
                  <img src={offerShapeImage} alt="Offer" />
                </div>
              </div>
              <div className="offer-item">
                <div className="offer-top">
                  <img src={offerImage04} alt="Offer" />
                  <i className="flaticon-defribillator" />
                </div>
                <div className="offer-bottom">
                  <h3>
                    <Link to="/oil-gas">Oil & Gas</Link>
                  </h3>
                  {/*<p>*/}
                  {/*  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do*/}
                  {/*  eiusmod{" "}*/}
                  {/*</p>*/}
                  {/*<a className="offer-link" href="service-details.html">*/}
                  {/*  Read More*/}
                  {/*  <i className="bx bx-plus" />*/}
                  {/*</a>*/}
                </div>
                <div className="offer-shape">
                  <img src={offerShapeImage} alt="Offer" />
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* End Offer */}
      </>



    );
}

export default HomeServices;