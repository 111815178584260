import React, {useEffect} from 'react';
import {NavLink} from "react-router-dom";
import logo from "../../assets/img/logo.png";
import whiteLogo from "../../assets/img/logo-three.png";
import deptLogo from "../../assets/images/dept-unizik-logo.png";
import deptDarkLogo from "../../assets/images/dept-unizik-logo-dark.png";
// import whiteLogo from "../../assets/images//white-logo.png";
// import TopSideBarModal from "./TopSideBarModal";

const Navigation = () => {

  useEffect(() => {

  }, []);


    return (
      <>
        {/* Start Navbar Area */}
        <div className="navbar-area sticky-top">
          {/* Mobile Device */}
          <div className="mobile-nav">
            <NavLink to="/" className="logo">
              <img src={whiteLogo} alt="Logo" />
            </NavLink>
          </div>
          {/* Desktop Device */}
          <div className="main-nav">
            <div className="container">
              <nav className="navbar navbar-expand-md navbar-light">
                <NavLink className="navbar-brand" to="/">
                  <img src={logo} className="main-logo" alt="Logo" />
                  <img
                    src={whiteLogo}
                    className="white-logo"
                    alt="Logo"
                    style={{maxWidth: '30px'}}
                  />
                </NavLink>
                <div
                  className="collapse navbar-collapse mean-menu"
                  id="navbarSupportedContent"
                >
                  <ul className="navbar-nav">
                    <li className="nav-item">
                      <NavLink to="/" className="nav-link" style={{fontSize: 20}}>
                        Home
                      </NavLink>
                    </li>
                    <li className="nav-item">
                      <NavLink to="/about" className="nav-link" style={{fontSize: 20}}>
                        About
                      </NavLink>
                    </li>
                    <li className="nav-item">
                      <NavLink to="/services" className="nav-link dropdown-toggle" style={{fontSize: 20}}>
                        Services <i className="bx bx-chevron-down" />
                      </NavLink>
                      <ul className="dropdown-menu">
                        <li className="nav-item">
                          <NavLink to="/oil-gas" className="nav-link" style={{fontSize: 20}}>
                            Oil & Gas
                          </NavLink>
                        </li>
                        <li className="nav-item">
                          <NavLink to="/agro-supply" className="nav-link" style={{fontSize: 20}}>
                            Agro-allied Supply Services
                          </NavLink>
                        </li>
                        <li className="nav-item">
                          <NavLink to="/facility-management" className="nav-link" style={{fontSize: 20}}>
                            Facility & Equipment Management
                          </NavLink>
                        </li>
                        <li className="nav-item">
                          <NavLink to="/supply-management" className="nav-link" style={{fontSize: 20}}>
                            Supply Chain Management
                          </NavLink>
                        </li>
                      </ul>
                    </li>
                    <li className="nav-item">
                      <NavLink to="/products" className="nav-link dropdown-toggle" style={{fontSize: 20}}>
                        Products <i className="bx bx-chevron-down" />
                      </NavLink>
                      <ul className="dropdown-menu">
                        <li className="nav-item">
                          <NavLink to="/palm-olein" className="nav-link" style={{fontSize: 20}}>
                            Palm Olein
                          </NavLink>
                        </li>
                        <li className="nav-item">
                          <NavLink to="/palm-stearin" className="nav-link" style={{fontSize: 20}}>
                            Palm Stearin
                          </NavLink>
                        </li>
                        <li className="nav-item">
                          <NavLink to="/crude-palm-oil" className="nav-link" style={{fontSize: 20}}>
                            Crude Palm Oil
                          </NavLink>
                        </li>
                        <li className="nav-item">
                          <NavLink to="/special-palm-oil" className="nav-link" style={{fontSize: 20}}>
                            Special Palm Oil
                          </NavLink>
                        </li>
                        <li className="nav-item">
                          <NavLink to="/refined-palm-kernel-oil" className="nav-link" style={{fontSize: 20}}>
                            Refined Palm Kernel Oil
                          </NavLink>
                        </li>
                        <li className="nav-item">
                          <NavLink to="/palm-fatty-acid-distillate" className="nav-link" style={{fontSize: 20}}>
                            Palm Fatty Acid Distillate
                          </NavLink>
                        </li>
                        <li className="nav-item">
                          <NavLink to="/bleached-palm-oil" className="nav-link" style={{fontSize: 20}}>
                            Refined Bleached & Deodorized Palm Oil
                          </NavLink>
                        </li>
                      </ul>
                    </li>
                    <li className="nav-item">
                      <NavLink to="/careers" className="nav-link" style={{fontSize: 20}}>
                        Careers
                      </NavLink>
                    </li>
                    <li className="nav-item">
                      <NavLink to="/contact" className="nav-link" style={{fontSize: 20}}>
                        Contact
                      </NavLink>
                    </li>
                  </ul>
                  {/*<div className="side-nav">*/}
                  {/*  <a href="#">Get A Quote</a>*/}
                  {/*</div>*/}
                </div>
              </nav>
            </div>
          </div>
        </div>
        {/* End Navbar Area */}
      </>

    );
}

export default Navigation;