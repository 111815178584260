import React from "react";
// import { v4 as uuidv4 } from "uuid";
import {serviceSideBarList} from "../../../_components/service_sidebar_links";
import {NavLink} from "react-router-dom";
import CallFunctions from "../../../_components/CallFunctions";
import ContactForm from "../../../Contact/ContactForm";


type Props = {
  serviceName: string,
  serviceDescriptionP1: string,
  serviceDescriptionP2?: string,
  serviceDescriptionP3?: string,
  serviceMainImage?: string,
  serviceImage1?: string,
  serviceImage2?: string,
  serviceImage3?: string,
  serviceImage4?: string,
  serviceImage5?: string,
  serviceImage6?: string,
  serviceImage7?: string,
  serviceImage8?: string,
  serviceImage9?: string,
  serviceImage10?: string,
  serviceImage11?: string,
  serviceImage12?: string,
  serviceImage13?: string,
  serviceImage14?: string,
  serviceImage15?: string,
  serviceVideo1?: string,
  serviceVideo2?: string,
  serviceVideo3?: string,
  serviceVideo4?: string,
  serviceVideo5?: string,
  serviceVideo6?: string,
  serviceVideo7?: string,
  serviceVideo8?: string,
  serviceVideo9?: string,
  serviceVideo10?: string,
  serviceVideo11?: string,
  serviceVideo12?: string,

}

const SingleServiceBody = (props: Props) => {

  CallFunctions()

  return (
    <>
      {/* Service Details */}
      <div className="project-details-area pt-100 pb-70">
        <div className="container">
          <div className="row">
            <div className="col-lg-9">
              <div className="project-details-item">
                <h2> {props.serviceName}</h2>
                <div className="project-details-img">
                  <img
                    src={props.serviceMainImage}
                    alt="Service Details"
                  />
                  <p> {props.serviceDescriptionP1} </p>

                  <p> {props.serviceDescriptionP2} </p>

                  <p> {props.serviceDescriptionP3} </p>

                </div>
                <div className="project-details-content">
                  <div className="row">
                    {props.serviceImage1 &&

                      <div className="col-sm-6 col-lg-4">
                        <img
                          src={props.serviceImage1}
                          alt="Service Details"
                        />
                      </div>
                    }

                    {props.serviceImage2 &&

                      <div className="col-sm-6 col-lg-4 video-wrap">
                        <img
                          src={props.serviceImage2}
                          alt="Service Details"
                        />
                        {props.serviceVideo2 &&

                            <a
                                href={props.serviceVideo2}
                                className="popup-youtube"
                            >
                                <i className="bx bx-play" />
                            </a>
                        }

                      </div>
                    }

                    {props.serviceImage3 &&

                      <div className="col-sm-6 col-lg-4">
                        <img
                          src={props.serviceImage3}
                          alt="Service Details"
                        />
                      </div>
                    }

                    {props.serviceImage4 &&

                      <div className="col-sm-6 col-lg-4">
                        <img
                          src={props.serviceImage4}
                          alt="Service Details"
                        />
                      </div>
                    }

                    {props.serviceImage5 &&

                        <div className="col-sm-6 col-lg-4">
                            <img
                                src={props.serviceImage5}
                                alt="Service Details"
                            />
                        </div>
                    }

                    {props.serviceImage6 &&

                        <div className="col-sm-6 col-lg-4">
                            <img
                                src={props.serviceImage6}
                                alt="Service Details"
                            />
                        </div>
                    }

                    {props.serviceImage7 &&

                        <div className="col-sm-6 col-lg-4 video-wrap">
                            <img
                                src={props.serviceImage7}
                                alt="Service Details"
                            />

                          {props.serviceVideo7 &&

                              <a
                                  href={props.serviceVideo7}
                                  className="popup-youtube"
                              >
                                  <i className="bx bx-play" />
                              </a>
                          }
                        </div>
                    }

                    {props.serviceImage8 &&

                        <div className="col-sm-6 col-lg-4">
                            <img
                                src={props.serviceImage8}
                                alt="Service Details"
                            />
                        </div>
                    }

                    {props.serviceImage9 &&

                        <div className="col-sm-6 col-lg-4">
                            <img
                                src={props.serviceImage9}
                                alt="Service Details"
                            />
                        </div>
                    }

                    {props.serviceImage10 &&

                        <div className="col-sm-6 col-lg-4">
                            <img
                                src={props.serviceImage10}
                                alt="Service Details"
                            />
                        </div>
                    }

                    {props.serviceImage11 &&

                        <div className="col-sm-6 col-lg-4 video-wrap">
                            <img
                                src={props.serviceImage11}
                                alt="Service Details"
                            />

                          {props.serviceVideo11 &&

                              <a
                                  href={props.serviceVideo11}
                                  className="popup-youtube"
                              >
                                  <i className="bx bx-play" />
                              </a>
                          }
                        </div>
                    }

                    {props.serviceImage12 &&

                        <div className="col-sm-6 col-lg-4">
                            <img
                                src={props.serviceImage12}
                                alt="Service Details"
                            />
                        </div>
                    }

                    {props.serviceImage13 &&

                        <div className="col-sm-6 col-lg-4">
                            <img
                                src={props.serviceImage13}
                                alt="Service Details"
                            />
                        </div>
                    }

                    {props.serviceImage14 &&

                        <div className="col-sm-6 col-lg-4">
                            <img
                                src={props.serviceImage14}
                                alt="Service Details"
                            />
                        </div>
                    }

                    {props.serviceImage15 &&

                        <div className="col-sm-6 col-lg-4">
                            <img
                                src={props.serviceImage15}
                                alt="Service Details"
                            />
                        </div>
                    }


                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="project-details-item">
                <div className="project-details-mec">
                  <h3>Other Services</h3>
                  <ul>
                    {
                      serviceSideBarList.map(list => {

                        if (list.name !== props.serviceName) {
                          return <>
                            <li key={list.id}>
                              <NavLink to={list.href}>
                                {list.name}
                                <i className="bx bx-chevron-right" />
                              </NavLink>
                            </li>
                          </>
                        }

                        return '';
                      })
                    }
                  </ul>
                </div>
                <div className="project-details-form">
                  <ContactForm />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* End Service Details */}
    </>

);
}

export default SingleServiceBody;