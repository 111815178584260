import React from 'react';
import { Outlet } from "react-router-dom"
import Layout from "../Layouts/Layout";
import PageTitle from "../_components/PageTitle";
import ProductsBody from "./ProductsBody";
import PagePreFooter from "../_components/PagePreFooter";

const Products = () => {

  return (
    <>
      <Layout>
        <PageTitle
          title1={`Our Products`}
          link1={`Our Products`}
          link2={``}
        />

        <ProductsBody />

        <PagePreFooter />
      </Layout>

      <Outlet />
    </>
  );
}

export default Products;