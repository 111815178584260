// import { v4 as uuidv4 } from "uuid";
import {productSideBarList} from "../../../_components/product_sidebar_links";
import {NavLink} from "react-router-dom";


type Props = {
  productName: string,
  productDescription: string,
  productImage?: string,
  listPoints: string[]

}

const SingleProductBody = (props: Props) => {

  // let uuid = uuidv4()

  // let counter = 1;

  return (
    <>
      {/* SingleProductBody Details */}
      <div className="service-details-area pt-100 pb-70">
        <div className="container">
          <div className="row">
            <div className="col-lg-8">
              <div className="service-details-item">
                <div className="service-details-robot">
                  <h2> {props.productName} </h2>
                  <p> {props.productDescription} </p>
                  <div className="row align-items-center">
                    <div className="col-lg-6">
                      <div className="robot-right">
                        <img
                          src={props.productImage}
                          alt="Product Details"
                        />
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="robot-left">
                        <ul>

                          {
                            props.listPoints.map(list => {

                              if (list !== undefined) {
                                return <>
                                  <li>
                                    <i className="bx bx-check" />
                                    {list}
                                  </li>
                                </>
                              }

                              return '';
                            })
                          }

                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="service-details-item">
                <div className="service-details-cat">
                  <h3>Other Products</h3>
                  <ul>
                    {
                      productSideBarList.map(list => {

                        if (list.name !== props.productName) {
                          return <>
                            <li key={list.id} style={{textAlign: 'left'}}>
                              <NavLink to={list.href}>{list.name}</NavLink>
                            </li>
                          </>
                        }

                        return '';
                      })
                    }

                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* End SingleProductBody Details */}
    </>

);
}

export default SingleProductBody;