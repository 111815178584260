import React from 'react';
import Layout from "../Layouts/Layout";
import HomeBannerArea from "./HomeBannerArea";
import HomeClientLogoArea from "./HomeClientLogoArea";
import HomeAbout from "./HomeAbout";
import HomeServices from "./HomeServices";
import PagePreFooter from "../_components/PagePreFooter";
import CallFunctions from "../_components/CallFunctions";
import HomeProductsServices from "./HomeProductsServices";

const Home = () => {

  CallFunctions()

    return (
       <>
         <Layout>
           <HomeBannerArea />
           <HomeAbout />

           <HomeProductsServices />
           <HomeServices />
           <PagePreFooter />
           <HomeClientLogoArea />
           {/*<HomePosts />*/}



           {/*<PageContent>*/}
           {/*  <HomePortfolio/>*/}
           {/*  <HomeFeatures/>*/}
           {/*  <HomeForm/>*/}
           {/*</PageContent>*/}

         </Layout>
       </>
    );
}

export default Home;