import React, {useRef, useState} from 'react';

import axios from "axios";
import Swal from "sweetalert2";

const ContactForm = () => {

  const initialState = {
    name: '',
    email: '',
    phone: '',
    message: '',
    isSubmitting: false,
    errorMessage: null
  };
  const [data, setData] = useState(initialState);
  const handleInputChange = (event: { target: { name: string; value: string; }; }) => {
    setData({
      ...data,
      [event.target.name]: event.target.value
    });
  };

  // const [name, setName] = useState('');
  // const [email, setEmail] = useState('');
  // const [phone, setPhone] = useState('');
  // const [message, setMessage] = useState('');

  const myRef = useRef(null);

  const handleSubmit = async (e: { preventDefault: () => void; currentTarget: any; }) => {
    e.preventDefault();

    // const element2 = myRef.current;
    // console.log(element2);
    // const event_button = e.currentTarget;

    // event_button.setAttribute("data-kt-indicator", "on");
    // event_button.disabled = true;

    // type FormData = {
    //   name: string,
    //   email: string,
    //   phone: number,
    //   message: string
    // }

    // let formData: FormData = {name, email, phone: parseInt(phone), message};

    type FormData = {
      name: string,
      email: string,
      phone: string,
      message: string
    }
    let formData: FormData = data;
    // console.log(formData)

    try{

      // const loginResponse = await axios.post(`https://jsonplaceholder.typicode.com/todos/1`, formData, {
      //   headers: {
      //     "Content-Type": "application/json",
      //     withCredentials: true
      //   },
      // })

      setData({
        ...data,
        isSubmitting: true,
        errorMessage: null
      });



      // console.log(data)

      const loginResponse = await axios.get(`https://jsonplaceholder.typicode.com/users`);



      await setData({
        ...initialState,
        isSubmitting: false,
        errorMessage: null
      });

      await Swal.fire({
        title: 'Success!',
        html: 'Message sent successfully!',
        icon: 'success',
        showConfirmButton: false,
        timer: 3000
      })

      // console.log(loginResponse);
      // console.log(formData);

      // event_button.removeAttribute( "data-kt-indicator");
      // event_button.disabled = false;
    }
    catch (err: any) {

      // event_button.removeAttribute( "data-kt-indicator");
      // event_button.disabled = false;
      // const errorMessage = err.message

      await setData({
        ...data,
        errorMessage: err.message
      });

      console.log(e);
    } finally {

      if (data.errorMessage === null) {
        await setData({
          ...initialState,
          isSubmitting: false
        });
      }
      else {
        await setData({
          ...data,
          isSubmitting: false
        });
      }

    }
  }

  return (
    <>
      <div className="contact-item contact-right">
        <h3>Leave a message</h3>
        <form id="contactForm">
          <div className="row">
            <div className="col-sm-8 col-lg-8">
              <div className="form-group">
                <input
                  type="text"
                  name="name"
                  id="name"
                  className="form-control"
                  required={true}
                  data-error="Please enter your name"
                  placeholder="Name"
                  value={data.name}
                  onChange={handleInputChange}
                />
                <div className="help-block with-errors" />
              </div>
            </div>
            <div className="col-sm-6 col-lg-6">
              <div className="form-group">
                <input
                  type="email"
                  name="email"
                  id="email"
                  className="form-control"
                  required={true}
                  data-error="Please enter your email"
                  placeholder="Email"
                  value={data.email}
                  onChange={handleInputChange}
                />
                <div className="help-block with-errors" />
              </div>
            </div>
            <div className="col-sm-6 col-lg-6">
              <div className="form-group">
                <input
                  type="text"
                  name="phone"
                  id="phone"
                  required={true}
                  data-error="Please enter your number"
                  className="form-control"
                  placeholder="Phone"
                  value={data.phone}
                  onChange={handleInputChange}
                />
                <div className="help-block with-errors" />
              </div>
            </div>
            <div className="col-md-12 col-lg-12">
              <div className="form-group">
                  <textarea
                    name="message"
                    className="form-control"
                    id="message"
                    cols={30}
                    rows={4}
                    required={true}
                    data-error="Write your message"
                    placeholder="Message"
                    value={data.message}
                    onChange={handleInputChange}
                  ></textarea>
                <div className="help-block with-errors" />
              </div>
            </div>
            {data.errorMessage && (
              <span className="form-error">{data.errorMessage}</span>
            )}
            <div className="col-md-12 col-lg-12">
              <button
                type="submit"
                className="contact-btn btn"
                ref={myRef}
                onClick={handleSubmit}
                disabled={data.isSubmitting}
              >
                {data.isSubmitting ? (
                  <span>Message sending ....</span>
                ) : (
                  <span>Send Message</span>
                )}
              </button>
              <div id="msgSubmit" className="h3 text-center hidden" />
              <div className="clearfix" />
            </div>
          </div>
        </form>
      </div>
    </>
  );
}

export default ContactForm;